import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Table } from "react-bootstrap"

const TarifsPage = () => (
    <Layout>
        <SEO title="Tarifs" />
        <Container>
            <h1 style={{ marginTop: "2rem" }}>Tarifs</h1>

            <p style={{ margin: "2rem 0px 2rem" }}>
                Les tarifs sont conçus pour pouvoir assurer un départ à partir de 4 personnes. Pour les départs qui compteront plus de 8 personnes, des tarifs dégressifs seront appliqués sur le tarif individuel. Pour réserver, le versement d'un acompte de 30 % du montant du prix total  est demandé. En cas d'annulation de votre part avant les trois dernières semaines précédant le départ, ce montant vous sera remboursé. Si vous annulez moins de trois semaines avant le départ, cette somme sera considérée comme due au titre de dédommagement. La montagne est un milieu changeant, et qui peut s'avérer dangereux si les conditions se dégradent. Si la pluie du matin n'arrête pas le pélerin, je me réserve la possibilité d'annuler la prestation si je ne juge pas les conditions adéquates à la bonne réalisation du projet. En ce cas, une ou plusieurs alternatives (changement de date et/ou d'itinéraire) vous seront proposées ou votre réservation remboursée.
            </p>
            <Table className="color-text" bordered responsive>
                <thead>
                    <tr>
                        <th>{""}</th>
                        <th>Durée mini</th>
                        <th>Durée maxi</th>
                        <th>Tarif groupe</th>
                        <th>Tarif individuel - adulte</th>
                        <th>Tarif individuel - enfant</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"Encadrement petite demi-journée"}</td>
                        <td>{"2h30"}</td>
                        <td>{"3h00"}</td>
                        <td>{"70 €"}</td>
                        <td>{"17 €"}</td>
                        <td>{"12 €"}</td>
                    </tr>
                    <tr>
                        <td>{"Encadrement grande demi-journée"}</td>
                        <td>{"4h00"}</td>
                        <td>{"5h00"}</td>
                        <td>{"100 €"}</td>
                        <td>{"25 €"}</td>
                        <td>{"17 €"}</td>
                    </tr>
                    <tr>
                        <td>{"Encadrement petite journée"}</td>
                        <td>{"6h00"}</td>
                        <td>{"8h00"}</td>
                        <td>{"180 €"}</td>
                        <td>{"45 €"}</td>
                        <td>{"30 €"}</td>
                    </tr>
                    <tr>
                        <td>{"Encadrement grande journée"}</td>
                        <td>{"8h00"}</td>
                        <td>{"12h00"}</td>
                        <td>{"240 €"}</td>
                        <td>{"60 €"}</td>
                        <td>{"40 €"}</td>

                    </tr>



                </tbody>
            </Table>
        </Container>
    </Layout>
)

export default TarifsPage